
export default {
  props: {
    variant: {
      type: String,
      default: 'primary-brand', // primary-brand, primary-white, secondary-white, tertiary-brand
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClass() {
      const baseClass = 'inline-flex items-center justify-center rounded-full font-semibold disabled:cursor-default'
      const paddingClass = this.isIcon ? 'w-9 h-9 md:w-10 md:h-10 lg:w-12 lg:h-12 p-2 md:p-2.5 lg:p-3' : 'py-2 px-3.5 md:py-2.5 md:px-4 lg:py-3 lg:px-[18px]'
      const textClass = this.isIcon ? 'text-xl md:text-2xl' : 'font-noto-sans text-sm lg:text-base'
      const gapClass = this.isIcon ? 'gap-1 md:gap-1.5' : 'gap-1.5'
      return `${baseClass} ${paddingClass} ${gapClass} ${textClass} ${this.color}`
    },
    color() {
      const list = {
        'primary-brand': 'bg-gank-red-3 border border-gank-red-3 text-white hover:bg-[#E5242F] hover:border-[#E5242F] focus:bg-[#C53B43] focus:border-[#C53B43] disabled:bg-[#751821] disabled:border-[#C53B43] disabled:text-[#67687E]',
        'primary-white': 'bg-white border border-white text-gank-gray hover:bg-[E0E0ED] hover:border-[E0E0ED] hover:text-gank-gray-2 disabled:bg-gank-gray-6 disabled:border-[#D0D1E1] disabled:text-gank-gray-5',
        'secondary-brand': 'border border-gank-red-3 text-white hover:border-[#E5242F] hover:text-[#E5242F] disabled:border-[#751821] disabled:text-[#751821]',
        'secondary-white': 'border border-white text-white hover:border-gank-gray-6 hover:text-gank-gray-6 disabled:border-gank-gray-4 disabled:text-gank-gray-4',
        'tertiary-white': 'text-white hover:bg-ds-gray-90 disabled:text-gank-gray-4 disabled:bg-transparent',
        'tertiary-brand': 'text-gank-red-3 hover:bg-gank-gray-11 disabled:text-[#751821] disabled:bg-transparent',
      }
      return list[this.variant] || list['primary-brand']
    },
  },
}
